<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
  <div class="container-fluid mt-4">
    <b-alert :show="loading" variant="info">Loading...</b-alert>
    <b-row>
      <b-col>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Username/Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Phone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.userName }}</td>
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>{{ user.phone }}</td>
              <td class="text-right">
                <b-row>
                  <b-button
                    v-if="isIntentoSales"
                    variant="outline-primary"
                    size="sm"
                    class="m-1"
                    @click.prevent="populateCustomerAccountManagerToEdit(user)"
                  >
                    <b-icon-pencil />
                  </b-button>
                  <b-button
                    v-if="isIntentoSales"
                    variant="outline-primary"
                    size="sm"
                    class="my-1"
                    @click.prevent="
                      deleteCustomerAccountManager(user.id, user.userName)
                    "
                  >
                    <b-icon-trash />
                  </b-button>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-modal
        id="modal-add-user"
        @ok="saveCustomerAccountManager"
        ok-title="Save Customer Account Manager"
        :title="model.id ? 'Edit Account Manager' : 'New Account Manager'"
      >
        <form @submit.prevent="saveCustomerAccountManager">
          <b-form-group label="Email">
            <b-form-input type="email" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group label="Password">
            <b-row>
              <b-col>
                <b-form-input
                  :type="passwordFieldType"
                  v-on:click="showPasswordInstructions = true"
                  v-model="model.password"
                />
              </b-col>
              <b-col md="auto">
                <b-icon-eye
                  class="center"
                  @mouseover="passwordFieldType = 'text'"
                  @mouseleave="passwordFieldType = 'password'"
                />
              </b-col>
            </b-row>
            <div v-show="showPasswordInstructions" class="form-group">
              The new password will need:
              <ul>
                <li>At least eight characters</li>
                <li>At least one upper case English letter</li>
                <li>At least one lower case English letter</li>
                <li>At least one digit</li>
                <li>
                  At least one special character from this list: #?!@$%^&*-{}~
                </li>
              </ul>
            </div>
          </b-form-group>
          <b-form-group label="First Name">
            <b-form-input type="text" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group label="Last Name">
            <b-form-input type="text" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group label="Phone">
            <b-form-input type="number" v-model="model.phone"></b-form-input>
          </b-form-group>
        </form>
        <div class="form-group">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
        </div>
      </b-modal>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          v-if="isIntentoSales"
          size="sm"
          class="m-1"
          variant="primary"
          @click.prevent="showNewCustomerAccountForm()"
        >
          <b-icon-plus font-scale="1.3"></b-icon-plus>Add Account Manager
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      loading: false,
      users: [],
      model: {},
      roleTypes: [
        {
          value: "CustomerAccountManager",
          text: "Account Manager",
        },
      ],
      showPasswordInstructions: false,
      errorMessage: "",
      passwordFieldType: "password",
    };
  },
  async created() {
    this.refreshCustomerAccountManagers();
  },
  computed: {
    ...mapGetters("auth", ["isIntentoSales"]),
  },
  methods: {
    async refreshCustomerAccountManagers() {
      this.loading = true;
      this.users = await ApiService.getCustomerUsers(this.$parent.customerId);
      this.loading = false;
    },
    async showNewCustomerAccountForm() {
      this.model = {};
      this.errorMessage = "";
      this.$bvModal.show("modal-add-user");
    },
    async populateCustomerAccountManagerToEdit(user) {
      this.model = Object.assign({}, user);
      this.errorMessage = "";
      this.$bvModal.show("modal-add-user");
    },
    async saveCustomerAccountManager(event) {
      event.preventDefault();
      this.model.userName = this.model.email;
      this.model.type = "CustomerAccountUser";
      if (this.model.id) {
        await ApiService.updateUser(this.model.id, this.model).then(
          () => {
            this.loading = false;
            this.model = {}; // reset form
            this.errorMessage = "";
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        const customerAccountId = Number(this.$parent.customerId);
        this.model.customerAccountId = customerAccountId;
        await ApiService.addUser(this.model).then(
          () => {
            this.loading = false;
            this.model = {}; // reset form
            this.errorMessage = "";
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
      await this.refreshCustomerAccountManagers();
    },
    async deleteCustomerAccountManager(id, username) {
      if (confirm(`Are you sure you want to delete user ${username} ?`)) {
        // if we are editing a user we deleted, remove it from the form
        if (this.model.Id === id) {
          this.model = {};
        }
        await ApiService.deleteUser(id);
        await this.refreshCustomerAccountManagers();
      }
    },
  },
};
</script>
